export function liveTVSubtabsGenerator(): any {
  return {
    currentPage: {
      displayName: 'TV',
      path: '/tv/',
      displayTemplate: 'gabaritList',
      displayType: 'subtabs',
    },
    tracking: {},
    meta: {},
    displayParameters: {},
    pageParameters: {
      displayType: 'subtabs',
    },
    buttons: [
      {
        type: 'secondary',
        picto: 'tvGuide',
        URLPicto: '?',
        label: 'Guide TV',
        onClick: {
          path: '/guide-tv/',
          displayTemplate: 'gabaritList',
          displayType: 'subtabs',
          URLPage:
            'https://hodor.canalplus.pro/api/v2/mycanal/page/14bf895c72be147bfcad67eeb09a6938/102570.json?aegon=true',
          parameters: [],
        },
      },
    ],
    rubriques: [
      {
        displayTemplate: 'subRubriques',
        displayName: 'Maintenant',
        default: 'true',
        subRubriques: [
          {
            displayTemplate: 'liveGrid',
            displayName: 'Favoris',
            path: '/live/tab/favoris',
            perso: 'favorites',
            displayAllChannels: true,
            displayOnlyFavoriteChannels: true,
            displayedPrograms: 'now',
            idRubrique: 'favorites',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102570.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Toutes les chaînes',
            path: '/live/tab/live-tv/en-direct-v5/pid114075-toutes-les-chaines-avec-multilive.html',
            default: true,
            displayAllChannels: true,
            displayedPrograms: 'now',
            idRubrique: 'allgenres',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/114075.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Sport',
            path: '/live/tab/direct/sport',
            displayedPrograms: 'now',
            idRubrique: '807',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102572.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Cinéma',
            path: '/live/tab/direct/cinema',
            displayedPrograms: 'now',
            idRubrique: '802',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102573.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Jeunesse',
            path: '/live/tab/direct/jeunesse',
            displayedPrograms: 'now',
            idRubrique: '804',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102574.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Infos',
            path: '/live/tab/direct/infos',
            displayedPrograms: 'now',
            idRubrique: '808',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102575.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Séries',
            path: '/live/tab/direct/series',
            displayedPrograms: 'now',
            idRubrique: '803',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102576.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Découverte',
            path: '/live/tab/direct/decouverte',
            displayedPrograms: 'now',
            idRubrique: '805',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102577.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Divertissement',
            path: '/live/tab/direct/divertissement',
            displayedPrograms: 'now',
            idRubrique: '806',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102578.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Musique',
            path: '/live/tab/direct/musique',
            displayedPrograms: 'now',
            idRubrique: '809',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102579.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Adulte',
            path: '/live/tab/direct/adulte',
            adult: true,
            displayedPrograms: 'now',
            idRubrique: '810',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102580.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
        ],
      },
      {
        displayTemplate: 'subRubriques',
        displayName: 'Ce soir',
        subRubriques: [
          {
            displayTemplate: 'liveGrid',
            displayName: 'Favoris',
            path: '/live/tab/favoris',
            perso: 'favorites',
            displayAllChannels: true,
            displayOnlyFavoriteChannels: true,
            displayedPrograms: 'now',
            idRubrique: 'favorites',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102570.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Toutes les chaînes',
            path: '/live/tab/live-tv/en-direct-v5/pid114075-toutes-les-chaines-avec-multilive.html',
            default: true,
            displayAllChannels: true,
            displayedPrograms: 'now',
            idRubrique: 'allgenres',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/114075.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Sport',
            path: '/live/tab/direct/sport',
            displayedPrograms: 'now',
            idRubrique: '807',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102572.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Cinéma',
            path: '/live/tab/direct/cinema',
            displayedPrograms: 'now',
            idRubrique: '802',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102573.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Jeunesse',
            path: '/live/tab/direct/jeunesse',
            displayedPrograms: 'now',
            idRubrique: '804',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102574.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Infos',
            path: '/live/tab/direct/infos',
            displayedPrograms: 'now',
            idRubrique: '808',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102575.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Séries',
            path: '/live/tab/direct/series',
            displayedPrograms: 'now',
            idRubrique: '803',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102576.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Découverte',
            path: '/live/tab/direct/decouverte',
            displayedPrograms: 'now',
            idRubrique: '805',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102577.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Divertissement',
            path: '/live/tab/direct/divertissement',
            displayedPrograms: 'now',
            idRubrique: '806',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102578.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Musique',
            path: '/live/tab/direct/musique',
            displayedPrograms: 'now',
            idRubrique: '809',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102579.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
          {
            displayTemplate: 'liveGrid',
            displayName: 'Adulte',
            path: '/live/tab/direct/adulte',
            adult: true,
            displayedPrograms: 'now',
            idRubrique: '810',
            URLPage:
              'https://hodor.canalplus.pro/api/v2/mycanal/page/f2cd0727f40ea89558d59ab2045e85d5/102580.json?aegon=true',
            parameters: [
              {
                in: 'parameters',
                id: 'featureToggles',
                enum: ['detailLight'],
              },
              {
                id: 'xx-epg-id',
                in: 'headers',
              },
            ],
          },
        ],
      },
    ],
  };
}
