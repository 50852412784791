import { SafetyCodeContext } from '@canalplus/types-acm';
import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { ApiV2PageGabaritList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import { liveTVSubtabsGenerator } from '@dce-front/onewebapp-mock-generators';
import { getRatio } from '@dce-front/onewebapp-utils';
import { useEffect, type JSX } from 'react';
import { useSelector } from 'react-redux';
import LoadableAdultDisclaimerConnected from '../../../components/AdultDisclaimer/LoadableAdultDisclaimerConnected';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useAppLocation } from '../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import type { FetchDetails } from '../../../services/types';
import { FetchRequestTypes } from '../../../services/types';
import { adultBrowsingSelector } from '../../../store/slices/adult-selectors';
import { setFirstLevelPageList } from '../../../store/slices/application';
import {
  getFeatureNewEPG,
  getFeatureToggleLiveTvFavorites,
  headerLevelPageListSelector,
} from '../../../store/slices/application-selectors';
import { hasUserDataCollectedSelector } from '../../../store/slices/user-selectors';
import { GabaritListContextProvider } from './GabaritListContext';
import GabaritListTemplate from './GabaritListTemplate';

export type GabaritListContainerProps = {
  url: string;
  onClickParameters?: FetchDetails['onClickParameters'];
  focusManager?: FocusManager;
  isMainTemplate?: boolean;
};

function GabaritListContainer({
  onClickParameters,
  focusManager,
  url,
  isMainTemplate,
}: GabaritListContainerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const featToggleLiveTvFavorites = useInvariantSelector(
    getFeatureToggleLiveTvFavorites,
  );
  const hasUserDataCollected = useSelector(hasUserDataCollectedSelector);
  const isAdultBrowsing = useSelector(adultBrowsingSelector);
  const headerLevelPageList = useInvariantSelector(headerLevelPageListSelector);
  const featNewEPG = useInvariantSelector(getFeatureNewEPG);
  const isLiveGridFavoritesEnabled =
    featToggleLiveTvFavorites && hasUserDataCollected;

  const [{ isLoading, data, error }] = useQueryTemplate<
    ApiV2PageGabaritList & { tracking?: Tracking }
  >(
    url,
    {
      onClickParameters,
      template: FetchRequestTypes.GabaritList,
      isMainTemplate,
    },
    undefined,
    { isLiveGridFavoritesEnabled },
  );

  const { currentPage, rubriques, displayParameters } = data || {};
  const { imageRatio } = displayParameters || {};

  const showAdultDisclaimer = currentPage?.adult && !isAdultBrowsing;

  useEffect(() => {
    if (
      data &&
      typeof data.currentPage?.path === 'string' &&
      headerLevelPageList.includes(data.currentPage.path) &&
      data.rubriques
    ) {
      dispatch(
        setFirstLevelPageList(
          data.rubriques
            .filter((rubrique) => typeof rubrique.path === 'string')
            .map((rubrique) => rubrique.path as string),
        ),
      );
    }
  }, [data, headerLevelPageList, dispatch]);

  // TODO: remove this when the feature toggle 'featNewEPG' is removed
  let rubrics = rubriques;
  let subRubrics: any = [];
  const location = useAppLocation();

  if (featNewEPG && location.pathname === '/tv/') {
    const dataWithSubRubrics = liveTVSubtabsGenerator();
    rubrics = dataWithSubRubrics.rubriques;
    const defaultRubric = dataWithSubRubrics.rubriques.find(
      (rubric: any) => rubric.default,
    );
    subRubrics = defaultRubric?.subRubriques || [];
  }
  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  return showAdultDisclaimer ? (
    <LoadableAdultDisclaimerConnected
      safetyCodeContext={SafetyCodeContext.Parental}
    />
  ) : (
    <GabaritListContextProvider>
      <MetaTagsTemplate data={data} enableAlternateLinksUpdate />
      <GabaritListTemplate
        title={currentPage?.displayName}
        rubrics={rubrics}
        subRubrics={subRubrics}
        focusManager={focusManager}
        imageRatio={getRatio({ imageRatio })}
      />
    </GabaritListContextProvider>
  );
}

export default GabaritListContainer;
