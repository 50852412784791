import { ExpandableMenu, Heading } from '@canalplus/mycanal-sharedcomponent';
import { PersoLists, Template } from '@canalplus/sdk-hodor';
import type { ApiV2PageRubrique } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import { Binder, enter, memory, spatial } from '@dce-front/one-navigation';
import { useTranslation } from '@dce-front/onewebapp-i18n';
import { Ratio, isSomeEnum } from '@dce-front/onewebapp-utils';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import GabaritListItem from '../../../components/GabaritList/GabaritListItem';
import { DATA_BINDER_GABARIT_LIST_TAB } from '../../../constants/gabaritList';
import { myCanalTitlesMapping } from '../../../constants/international';
import {
  useAppHistory,
  useAppLocation,
} from '../../../helpers/hooks/reactRouter';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import {
  HEADING_HEIGHT,
  scroll,
  scrollFocusedIntoHorizontalList,
  scrollFocusedIntoVerticalViewport,
} from '../../../helpers/oneNavigation/scroll';
import { hideTabHorizontalNavigation } from '../../../helpers/oneNavigation/tabs';
import {
  getFeatureNewEPG,
  isMobileSelector,
  offerLocationSelector,
} from '../../../store/slices/application-selectors';
import { pagePathSelector } from '../../../store/slices/page-selectors';
import { isMobileResolutionSelector } from '../../../store/slices/ui-selectors';
import LoadableContentGrid from '../../../templates/ContentGrid';
import LoadableContentGridPerso from '../../../templates/ContentGridPerso';
import LoadableLandingV5 from '../../../templates/LandingV5';
import LoadableLiveGrid from '../../../templates/LiveGrid';
import StubContainer from '../../../templates/Stub/components/StubContainer';
import styles from './GabaritListTemplate.css';

type GabaritListTemplateProps = {
  hideRubrics?: boolean;
  imageRatio?: Ratio;
  onRubricsChange?: (arg: ApiV2PageRubrique) => void;
  rubrics?: ApiV2PageRubrique[];
  subRubrics?: ApiV2PageRubrique[];
  focusManager?: FocusManager;
  title?: string | null;
};

function GabaritListTemplate({
  hideRubrics = false,
  imageRatio = Ratio.Ratio169,
  onRubricsChange,
  rubrics = [],
  subRubrics = [],
  focusManager,
  title,
}: GabaritListTemplateProps): JSX.Element | null {
  const { t } = useTranslation();

  const visibleRubricIndex =
    rubrics?.findIndex((rubric) => rubric.default) ?? -1;
  const defaultVisibleRubricIndex =
    visibleRubricIndex === -1 ? 0 : visibleRubricIndex;

  const visibleSubRubricIndex =
    subRubrics?.findIndex((rubric) => rubric.default) ?? -1;
  const defaultSubVisibleRubricIndex =
    visibleSubRubricIndex === -1 ? 0 : visibleSubRubricIndex;

  const [rubricsActiveIndex, setRubricsActiveIndex] = useState(
    defaultVisibleRubricIndex,
  );
  const [subRubricsActiveIndex, setSubRubricsActiveIndex] = useState(
    defaultSubVisibleRubricIndex,
  );

  const pagePathname = useSelector(pagePathSelector);
  const [isTabVisible, setIsTabVisible] = useState<boolean>(true);

  const createMiddlewareMenu = (defaultIndex: number) => [
    hideTabHorizontalNavigation(setIsTabVisible),
    scroll([
      scrollFocusedIntoVerticalViewport({
        margins: { top: HEADING_HEIGHT + 1.875 }, // 30px GabaritList menu margin-top
      }),
      scrollFocusedIntoHorizontalList(false, '#expandableMenu__list'),
    ]),
    enter({
      forceFocusIndex: defaultIndex,
      shouldForceFocusOnce: true,
    }),
    memory(),
    spatial(),
  ];

  const MIDDLEWARE_MENU = useMemo(
    () => createMiddlewareMenu(defaultVisibleRubricIndex),
    [defaultVisibleRubricIndex],
  );

  const MIDDLEWARE_SUB_MENU = useMemo(
    () => createMiddlewareMenu(defaultSubVisibleRubricIndex),
    [defaultSubVisibleRubricIndex],
  );

  const offerLocation = useInvariantSelector(offerLocationSelector);
  const featNewEPG = useInvariantSelector(getFeatureNewEPG);
  const myCanalTitle = myCanalTitlesMapping[offerLocation];
  const isMobileResolution = useSelector(isMobileResolutionSelector);
  const isMobile = useInvariantSelector(isMobileSelector);

  const { LiveGrid, Stub, ContentGrid, Landing } = Template;

  const history = useAppHistory();
  const location = useAppLocation();

  const activeRubric = rubrics[rubricsActiveIndex];
  const activeSubRubric = subRubrics[subRubricsActiveIndex];

  const titleTemplate = `${title ? `${title}: ` : ''}${activeRubric?.displayName || ''} | ${myCanalTitle || ''}`;

  useEffect(() => {
    const { pathname, search } = location;
    // firstParamOfPagePathname get '/live/' of '/live/tab/cinema'
    const firstParamOfPagePathname = pagePathname.replace(
      /(\/.[^/]*\/)(.*)/,
      '$1',
    );
    // To know if the user leaves the page we compare the location.pathname (target) with the page.pathname (current).
    // In this case, we prevent the update of the rubric to avoid redoing a call to a tab and triggering an unnecessary tracking page.
    const isPageLeft = !pathname.startsWith(firstParamOfPagePathname);
    if (isPageLeft) {
      return;
    }

    const pathnameWithParams = decodeURI(pathname + search);
    const currentRubricIndex = rubrics?.findIndex(
      (rubric) => rubric.path === pathnameWithParams,
    );

    const isOpenImmersiveContext =
      location?.state?.immersive?.context === 'immersive';

    if (
      !$_BUILD_RENDERMODE_CSR &&
      !isOpenImmersiveContext &&
      currentRubricIndex === -1
    ) {
      // if pathname is not in rubrics, it certainly the default hodor path
      setRubricsActiveIndex(defaultVisibleRubricIndex);
    } else if (!$_BUILD_RENDERMODE_CSR && currentRubricIndex >= 0) {
      // if pathname is in rubrics, so take the good rubric
      setRubricsActiveIndex(currentRubricIndex);
    }
    setIsTabVisible(true);
  }, [
    defaultVisibleRubricIndex,
    history,
    location,
    rubrics,
    rubricsActiveIndex,
    pagePathname,
    setIsTabVisible,
  ]);

  useEffect(() => {
    // Today it's only used on the livetv old player because the livetv does not fetch new hodor url when changing rubric, so tracking is done here
    if (onRubricsChange && typeof activeRubric?.idRubrique !== 'undefined') {
      onRubricsChange(activeRubric);
    }
  }, [activeRubric, activeRubric?.idRubrique, onRubricsChange]);

  const switchTab = useCallback(
    (index = 0) => {
      setIsTabVisible(true);
      setRubricsActiveIndex(index);
    },
    [setIsTabVisible, setRubricsActiveIndex],
  );

  const switchSubTab = useCallback(
    (index = 0) => setSubRubricsActiveIndex(index),
    [],
  );

  useOnFocusable(focusManager, rubrics.length > 0);

  const renderDisplayTemplate = () => {
    const {
      displayAllChannels,
      displayOnlyFavoriteChannels,
      displayTemplate,
      parameters,
      perso,
      URLPage,
    } = activeSubRubric || activeRubric || {};

    const displayPlaceholderChannelsForLiveGrid =
      displayAllChannels || displayOnlyFavoriteChannels;
    const persoType = isSomeEnum(PersoLists)(perso) ? perso : undefined;

    switch (displayTemplate) {
      // type ApiV2PageRubrique
      case ContentGrid:
        if (perso && URLPage) {
          return (
            <LoadableContentGridPerso
              persoType={persoType}
              url={URLPage}
              onClickParameters={parameters}
              isVirtualization
            />
          );
        } else {
          return (
            <LoadableContentGrid
              displayParameters={{ imageRatio }}
              url={URLPage}
              onClickParameters={parameters}
              isVirtualization
            />
          );
        }

      case LiveGrid:
        return (
          <LoadableLiveGrid
            displayPlaceholderChannels={displayPlaceholderChannelsForLiveGrid}
            isPerso={!!perso}
            url={URLPage}
            onClickParameters={parameters}
            focusManager={focusManager}
          />
        );

      case Landing:
        return (
          <LoadableLandingV5 url={URLPage} onClickParameters={parameters} />
        );

      case Stub:
        return URLPage ? (
          <StubContainer url={URLPage} onClickParameters={parameters} />
        ) : null;

      default:
        return <ErrorTemplate />;
    }
  };

  const isTabListShown = rubrics.length > 1 && !hideRubrics;

  if (!rubrics.length) {
    return null;
  }

  return (
    <div className={styles.gabaritListTemplate}>
      {/* TODO remove it ? */}
      {!$_BUILD_RENDERMODE_CSR && <Helmet titleTemplate={titleTemplate} />}
      <Binder
        binderId="gabaritListTemplate__header"
        middleware={MIDDLEWARE_MENU}
        data-binder={DATA_BINDER_GABARIT_LIST_TAB}
        enabled={$_BUILD_RENDERMODE_CSR && isTabListShown}
        className={classNames(
          styles.gabaritListTemplate__header,
          styles['gabaritListTemplate__header--multiline'],
        )}
      >
        {!$_BUILD_RENDERMODE_CSR && title && !subRubrics.length && (
          <Heading text={title} isBig />
        )}
        {isTabListShown && (
          <ExpandableMenu
            activeIndex={rubricsActiveIndex}
            isDropdownEnabled={
              !(isMobile && isMobileResolution) && !$_BUILD_RENDERMODE_CSR
            }
            themeClass={styles.expandableMenuTheme}
            isMobile={isMobile}
            isTvDevice={$_BUILD_RENDERMODE_CSR}
            dropdownTriggerTitle={t('Navigation.dropdownLabel')}
          >
            {rubrics.map((item, index) => (
              <GabaritListItem
                disabled={
                  !item.default && featNewEPG && location.pathname === '/tv/'
                }
                key={`${String(item.idRubrique)}-${String(index)}`}
                item={item}
                onClick={() => switchTab(index)}
                id={String(item.idRubrique)}
                isMainList={subRubrics.length > 0}
                navigateOnFocus={
                  $_BUILD_RENDERMODE_CSR && rubricsActiveIndex !== index
                }
              />
            ))}
          </ExpandableMenu>
        )}
      </Binder>
      {subRubrics.length > 0 && (
        <Binder
          binderId="gabaritListTemplate__subList"
          middleware={MIDDLEWARE_SUB_MENU}
          data-binder={'gabaritSubList'}
          enabled={$_BUILD_RENDERMODE_CSR && isTabListShown}
          className={classNames(
            styles.gabaritListTemplate__header,
            styles['gabaritListTemplate__header--multiline'],
            styles['gabaritListTemplate__header--subMenu'],
          )}
        >
          <ExpandableMenu
            activeIndex={subRubricsActiveIndex}
            isDropdownEnabled={
              !(isMobile && isMobileResolution) && !$_BUILD_RENDERMODE_CSR
            }
            themeClass={styles.expandableMenuTheme}
            isMobile={isMobile}
            isTvDevice={$_BUILD_RENDERMODE_CSR}
          >
            {subRubrics.map((item, index) => (
              <GabaritListItem
                key={`${String(item.idRubrique)}-${index}`}
                item={item}
                onClick={() => switchSubTab(index)}
                id={String(item.idRubrique)}
                navigateOnFocus={subRubricsActiveIndex !== index}
                disabled={!item.default}
              />
            ))}
          </ExpandableMenu>
        </Binder>
      )}
      <div
        className={classNames(styles.gabaritListTemplate__tabPanel, {
          [styles['gabaritListTemplate__tabPanel--hidden']!]: !isTabVisible,
        })}
      >
        {renderDisplayTemplate()}
      </div>
    </div>
  );
}

export default GabaritListTemplate;
